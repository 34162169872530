import ConfigStore from "./ConfigStore";
import * as _ from "lodash";
import axios from "axios";
import { logout } from "./Users/Logout";

export default class BackendService {
  basePath = "";
  configStore = ConfigStore;

  constructor(path) {
    this.basePath = this.configStore.backendUrl + path;

    axios.interceptors.response.use(
      response => response,
      error => {
        // console.log('interceptor error', error.response)
        if (!error || !error.response || error.response.status === 401) {
          return logout();
        }
        if (error.response.status === 403) window.location.href = "/";
        return Promise.reject(error);
      }
    );
    axios.defaults.headers.common = { Accept: "application/json" };
  }

  get = async (url = "") => {
    if (localStorage.getItem(`jwtToken`))
      axios.defaults.headers.common[`Authorization`] = `Bearer ${localStorage.getItem(`jwtToken`)}`.replace(`#`, ``);
    return axios.get(`${this.basePath}${url[0] === "?" ? "" : "/"}${url}`).then(r => {
      if (r === undefined) return logout();
      return r.data;
    });
  };

  post = (url = "", payload) => {
    console.log("this?", url, payload);
    axios.defaults.headers["Content-Type"] = "application/json";
    if (localStorage.getItem(`jwtToken`))
      axios.defaults.headers.common[`Authorization`] = `Bearer ${localStorage.getItem(`jwtToken`)}`.replace(`#`, ``);
    return axios.post(`${this.basePath}/${url}`, JSON.stringify(payload)).then(r => {
      if (r === undefined) return logout();
      return r.data;
    });
  };

  put = (url = "", payload) => {
    axios.defaults.headers["Content-Type"] = "application/json";
    if (localStorage.getItem(`jwtToken`))
      axios.defaults.headers.common[`Authorization`] = `Bearer ${localStorage.getItem(`jwtToken`)}`.replace(`#`, ``);
    return axios.put(`${this.basePath}/${url}`, JSON.stringify(payload)).then(r => {
      if (r === undefined) return logout();
      return r.data;
    });
  };

  delete = (url = "") => {
    axios.defaults.headers["Content-Type"] = "application/json";

    if (localStorage.getItem(`jwtToken`))
      axios.defaults.headers.common[`Authorization`] = `Bearer ${localStorage.getItem(`jwtToken`)}`.replace(`#`, ``);
    return axios.delete(`${this.basePath}/${url}`).then(r => {
      if (r === undefined) return logout();
      return r.data;
    });
  };

  deserializePayload(objectArray) {
    objectArray.forEach((object, index) => {
      if (object) {
        if (!_.isEmpty(object["payload"])) {
          object["payload"] = JSON.parse(object["payload"]);
          if (!object["payload"]["RiskStatus"]) object["payload"]["RiskStatus"] = ["-1"];
        }

        if (!_.isEmpty(object["children"])) {
          object["children"] = this.deserializePayload(object["children"]);
        }

        if (!_.isEmpty(object["documentsOfTask"])) {
          object["documentsOfTask"] = this.deserializePayload(object["documentsOfTask"]);
        }

        if (!_.isEmpty(object["containers"])) {
          object["containers"] = this.deserializePayload(object["containers"]);
        }
      }
    });

    return objectArray;
  }
}
