import React, { useEffect, useState } from "react";
import { Route, useHistory, useParams, useRouteMatch } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";

import ConfigStore from "./Common/ConfigStore";
import GraphQlService from "./Common/GraphQlService";
import BackendService from "./Common/BackendService";
import UserStore from "./Common/Users/UserStore";

// I18N
import "moment/locale/es";
import "moment/locale/en-gb";
import { Input, Modal, Spin, message } from "antd";
import { logout } from "./Common/Users/Logout";
import { drop } from "lodash";
import { toJS } from "mobx";
import ViewGeneratorStore from "./ViewGenerator/ViewGeneratorStore";

const AppRoute = ({ component: Component, layout: Layout, title: Title, path: Path, ...rest }) => {
  // console.log('AppRoute.useEffect', rest)
  const [title, setTitle] = useState(undefined);

  useEffect(() => {
    if (!ViewGeneratorStore.title) setTitle(Title);
    else setTitle(ViewGeneratorStore.title);
  }, [ViewGeneratorStore.title]);

  return (
    <Route
      {...rest}
      render={props => {
        return <AppRouteResult layout={Layout} title={title} path={Path} component={Component} props={props} />;
      }}
    />
  );
};

const AppRouteResult = ({ component: Component, layout: Layout, title: Title, path: Path, props }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [changePassword, setChangePassword] = useState({ isVisible: false });

  const history = useHistory();
  const routeMatch = useRouteMatch();
  const graphQlService = new GraphQlService();

  useEffect(() => {
    if (!localStorage.getItem(`jwtToken`) || window.location.href.includes(`utils/`)) setIsLoading(false);
    else loadUser();
  }, [routeMatch]);

  useEffect(() => {
    if (UserStore.user && UserStore.user.id) {
      window.pendo.initialize({
        visitor: { id: UserStore.user.id },
        account: { id: "ACCOUNT-UNIQUE-ID" }
      });
    }
  }, [UserStore.user]);

  const loadUser = async () => {
    // console.log('appRoute loadUser', routeMatch)

    if (routeMatch.path.includes(`:projectName?`)) {
      // && routeMatch.params.projectName
      var projectName = routeMatch.params.projectName;

      if (
        (!localStorage.getItem("projectName") || !localStorage.getItem("projectId")) &&
        (!projectName || projectName === "undefined" || projectName == "null")
      )
        await loadProject();
      if (!localStorage.getItem("tenantId")) await loadTenant();

      if (!projectName || projectName === "undefined" || projectName == "null") {
        var newUrl = newProjectUrl(props);
        history.push(newUrl);
        return;
      }

      if ((localStorage.getItem(`projectName`) || "").toLowerCase() !== (projectName || "").toLowerCase()) {
        const getProjectId = await graphQlService.get(`{ projectByName(name: "${projectName}") { id tenantId } }`);
        // console.log('AppRouteResult.loadUser.projectGet', getProjectId)
        if (getProjectId.errors || !getProjectId.data.projectByName) return logout(history);

        const switchProject = await graphQlService.post(
          `mutation switch($data: Guid, $tenantId: Guid) { projectSwitch(projectId: $data, tenantId: $tenantId) }`,
          {
            data: getProjectId.data.projectByName.id,
            tenantId: getProjectId.data.projectByName.tenantId
          }
        );
        var json = JSON.parse(switchProject.data.projectSwitch);
        localStorage.setItem(`jwtToken`, json.jwt);

        await loadProject();
        await loadTenant();

        return history.push(`/`);
      }

      const user = await graphQlService.get(`{ currentUser { id email otpSet } }`);
      if (!user || user.errors || !user.data || !user.data.currentUser) {
        return history.push(`/utils/login`);
      }
      if (user) {
        localStorage.setItem("email", user.data.currentUser.email);
        localStorage.setItem("id", user.data.currentUser.id);
        if (user.data.currentUser.otpSet) setChangePassword(() => ({ isVisible: true }));
      }
      await UserStore.loadUserAccess();
    }
    // console.log('load user end?')

    setIsLoading(false);
  };

  const newProjectUrl = props => {
    if (props.match.params.projectName)
      return `/${localStorage.getItem(`projectName`)}/${drop(props.match.url.split("/"), 2).join("/")}${
        props.location.search
      }${props.location.hash}`;
    return `/${localStorage.getItem(`projectName`)}${props.match.url}${props.location.search}${props.location.hash}`;
  };

  const loadProject = async () => {
    var project = await graphQlService.get(`{ project { value id }}`);
    // console.log('loadProject', project.data.project)
    if (project.errors) return logout(history);

    localStorage.setItem(`projectId`, project.data.project.id);
    localStorage.setItem(`projectName`, project.data.project.value);
  };

  const loadTenant = async () => {
    var tenant = await graphQlService.get(`{ tenant { rootId plan headerLogo }}`);
    // console.log('loadTenant', tenant.data.tenant)
    if (tenant.errors) return logout(history);

    localStorage.setItem(`tenantId`, tenant.data.tenant.rootId);
    localStorage.setItem(`plan`, tenant.data.tenant.plan);

    let appConfig = JSON.parse(localStorage.getItem(`appConfig`));
    appConfig.header.path = ConfigStore.backendUrlHost + "/" + tenant.headerLogo;
    localStorage.setItem(`appConfig`, JSON.stringify(appConfig));
  };

  if (isLoading)
    return (
      <div style={{ marginTop: "20%" }}>
        <div style={{ textAlign: "center" }}>
          <img
            src={"./Beawre_login_logo.png"}
            alt="Beawre"
            style={{ height: "42px", marginBottom: 30, marginTop: 40 }}
          />
        </div>
        <Spin spinning={true} size="large">
          <div style={{ height: 50, width: 50 }}></div>
        </Spin>
      </div>
    );

  return (
    <div>
      <DocumentTitle title={Title}>
        <Layout pageTitle={Title} path={Path}>
          <Component {...props} />
        </Layout>
      </DocumentTitle>

      <Modal
        title={"Set new password"}
        open={changePassword.isVisible}
        destroyOnClose
        closable={false}
        maskClosable={false}
        style={{ width: "90%" }}
        onOk={() => {
          setChangePassword(c => ({ ...c, isLoading: true }));
          graphQlService
            .post(`mutation post($data: ChangePasswordCommand) { userChangePassword(data: $data) }`, {
              data: { password: changePassword.password }
            })
            .then(r => {
              if (r.errors) {
                graphQlService.displayErrors(r.errors);
              } else message.success(`Password has been changed!`);
              setChangePassword(() => ({ isVisible: r.errors ? true : false, isLoading: false }));
            });
        }}
        confirmLoading={changePassword.isLoading}
      >
        <h3 style={{ textAlign: "center" }}>
          You have logged in using your One Time Password. <br />
          Please change it before continue.
        </h3>
        <Input.Password
          value={changePassword.password}
          onChange={e => setChangePassword(c => ({ ...c, password: e.target.value }))}
          placeholder={"Password"}
          style={{ width: "100%" }}
        />
      </Modal>
    </div>
  );
};

export default withTranslation()(observer(AppRoute));
